import React, { Component } from 'react';
import './App.css';


class App extends Component {

  
  render() {
    // function showAndroidToast(toast) {
    //   console.log('running this', toast)
    //   console.log(navigator.userAgent)
    //   if (window.Android) {
    //     console.log(window)
    //     window.Android.showToast(toast);
    //   }
    // }

    return (
      <div className="App">
        {/* <input type="button" value="Say hello" onClick={() => {showAndroidToast('Hello Android!')}} /> */}
      </div>
    );
  }
}

export default App;
